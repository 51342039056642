<template>
    <div id="ErpIntegrationRun">
        <v-container fluid app>
            <v-layout v-resize="onResize">
                <v-data-table
                    :headers="apiResult.headers"
                    :items="apiResult.items"
                    :items-per-page="30"
                    :footer-props="footerProps"
                    :page.sync="pagination.page"
                    @pagination="updatePage"
                    :item-key="getItemKey()"
                    :search="search"
                    :loading="loading"
                    loading-text="Carregando dados..."
                    class="elevation-1 text-no-wrap"
                    :height="noData ? '' : windowSize.y - 64 - 157"
                    fixed-header
                    style="width: 100%;"
                >
                    <template v-slot:top>
                        <v-toolbar flat class="rounded-lg">
                            <div class="d-flex align-start mr-4">
                                <v-icon x-large color="primary">
                                    {{ tableIcon }}
                                </v-icon>
                            </div>
                            <div class="d-flex flex-column align-start">
                                <span class="text-h6 font-weight-regular">{{ tableName }}</span>
                            </div>

                            <div class="d-flex align-center ml-8">
                                <img src="../../public/img/netsuite-logo-img.png" alt="Logomarca netsuite"
                                    style="width: 92px;"
                                >

                                <v-icon class="mx-4" color="black">mdi-arrow-right-thick</v-icon>

                                <img src="../../public/img/znap-app-bar.png" alt="Logomarca netsuite"
                                    style="width: 92px;"
                                >
                            </div>

                            <v-spacer></v-spacer>

                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$vuetify.lang.t('$vuetify.searchLabel')"
                                hide-details
                                single-line
                                dense
                                clearable
                                class="mr-4"
                                style="width: 1%;"
                            />

                            <v-tooltip top v-if="createPermission">
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        v-on="on"
                                        class="secondary mb-2 mr-4 pa-0"
                                        min-width="48px"
                                        @click="openDialogRunIntegrations()"
                                        :disabled="isRunningAllIntegrations || isRunningIndividualIntegration"
                                        :loading="isRunningAllIntegrations || isRunningIndividualIntegration"
                                    >
                                        <v-icon>{{ isRunningAllIntegrations || isRunningIndividualIntegration ? 'mdi-sync-off' : 'mdi-sync' }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>Processar integrações</span>
                            </v-tooltip>

                            <v-dialog v-model="dialogRunIntegrations" max-width="800px">
                                <v-card class="pa-6">
                                    <div class="d-flex justify-start ">
                                        <v-card-title class="headline text-center pa-0">Escolha o mês e ano para processar todas as integrações:</v-card-title>
                                    </div>

                                    <div class="mt-4">
                                        <v-row no-gutters>
                                            <v-col class="d-flex">
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="false"
                                                    :return-value.sync="picker"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            :value="formattedMonthYear"
                                                            label="Mês/ Ano"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-on="on"
                                                            class="mr-4"
                                                            style="max-width: 400px;"
                                                        ></v-text-field>
                                                    </template>

                                                    <v-date-picker color="primary"
                                                        v-model="picker"
                                                        type="month"
                                                        no-title
                                                        scrollable
                                                    >
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="menu = false"
                                                        >
                                                            Cancelar
                                                        </v-btn>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="$refs.menu.save(picker)"
                                                        >
                                                            Confirmar
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </div>

                                    <v-card-actions class="pa-0">
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="closeDialogRunIntegrations()" class="mr-4">Cancelar</v-btn>
                                        <v-btn color="primary" @click="handleRunIntegrations()">Confirmar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        color="primary"
                                        v-on="on"
                                        class="mb-2 mr-4 pa-0"
                                        min-width="48px"
                                        @click="refresh()"
                                    >
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Atualizar</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        color="primary" dark
                                        v-on="on"
                                        class="mb-2 pa-0"
                                        min-width="48px"
                                        @click="closeTable()"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>

                            <v-dialog v-model="dialogCloseTable" max-width="600px">
                                <v-card>
                                    <div class="d-flex justify-center">
                                        <v-card-title class="headline text-center">Você possui edições não salvas. Tem certeza de que deseja fechar a tabela?</v-card-title>
                                    </div>
                                    <v-card-actions class="px-6 pb-6">
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="dialogCloseTable = false" class="mr-4">Cancelar</v-btn>
                                        <v-btn color="primary" @click="$router.push('/')">Confirmar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>

                        <v-divider class="mx-4 mb-2"></v-divider>
                    </template>

                    <!-- eslint-disable-next-line -->
                    <template v-slot:item="{ item, index }">
                        <tr>
                            <template v-for="(header, headerIndex) in apiResult.headers">
                                <td v-if="header.value === 'actions'"
                                    :key="headerIndex"
                                    class="d-block d-sm-table-cell"
                                    style="width: 1%;"
                                >
                                    <v-tooltip top v-if="enableIndividualIntegration(item)">
                                        <template v-slot:activator="{on}">
                                            <v-btn small
                                                v-on="on"
                                                @click="selectIntegration(item)"
                                                class="primary"
                                                :loading="isRunningThisIndividualIntegration(item)"
                                                :disabled="isRunningThisIndividualIntegration(item)"
                                            >
                                                <v-icon small>{{ isRunningThisIndividualIntegration(item) ? 'mdi-sync-off' : 'mdi-sync' }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Processar integração</span>
                                    </v-tooltip>
                                </td>
                                

                                <td v-else :key="headerIndex" class="d-block d-sm-table-cell">
                                    <div v-if="header.align === 'center'" class="d-flex justify-center">
                                        <span>{{ item[header.value] }}</span>
                                    </div>

                                    <div v-else class="d-flex justify-start">
                                        <span>{{ item[header.value] }}</span>
                                    </div>
                                </td>
                            </template> 
                        </tr>
                    </template>
                
                    <template v-slot:no-data>
                        <span class="text-h6 primary--text">{{ noDataMessage }}</span>
                    </template>
                </v-data-table>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'ErpIntegrationRun',

    computed: {
        ...mapGetters('auth', ['getHash', 'getMenu']),

        endpoint() {
            return [ this.$ipIntegration, 'erp-integration' ]
        },

        tableName() {
            const tableName = this.getTableInfo().tableName
            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon
            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        formattedMonthYear() {
            return moment.utc(this.picker).format("MM/YYYY")
        },
    },

    data() {
        return {
            loading: false,
            isRunningAllIntegrations: false,
            isRunningIndividualIntegration: false,
            dialogRunIntegrations: false,

            selectedIntegration: null,
            runningIntegrations: [],

            tableRows: 10000,

            search: '',

            apiResult: {
                items: [],
                headers: [],
                columns: [],
            },

            pagination: {},
            backupPage: 1,
            footerProps: {
                itemsPerPageOptions: [5, 15, 30, 50],
                itemsPerPageAllText: 'Todos'
            },

            dialogCloseTable: false,
            unsavedChanges: false,

            noDataMessage: '',
            noData: true,

            windowSize: { x: 0, y: 0 },
            timeout: null,

            menu: false,
            picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),

            createPermission: true,
        }
    },

    created() {
        let routePath = this.$route.path
        if (this.$route.path === '/erp-integration') {
            routePath = '/erp-integration-run'
        }

        const permissions = this.$getPermissions(routePath)
        for (let key in permissions) {
            this[key] = permissions[key]
        }
        this.load()
    },

    beforeDestroy() {
        clearTimeout(this.timeout)
    },

    methods: {
        async getIntegrationStatus() {
            let payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_erp_integration",
                            operator: "IN",
                            value: this.apiResult.items.map(i => i.id_erp_integration)
                        },
                        {
                            AndOr: "AND",
                            column: "processing_status",
                            operator: "IN",
                            value: ['"WIP"', '"NEW"']
                        },
                        {
                            AndOr: "AND",
                            column: "from_erp_to_erp",
                            operator: "=",
                            value: "FROM_ERP"
                        }
                    ]
                }
            }

            try {
                const res = await this.$http.post(`${this.$ipIntegration}erp-integration-run/list`, { ...payload })
                if (res) {
                    if (res.data.rows.length) {
                        this.runningIntegrations = res.data.rows

                        let individualIntegrations = this.runningIntegrations.filter(i => {
                            return i.id_erp_integration === 13 || i.id_erp_integration === 14 || i.id_erp_integration === 15 || i.id_erp_integration === 17
                        })

                        if (this.runningIntegrations.length !== individualIntegrations.length) {
                            this.isRunningAllIntegrations = true
                        } else {
                            this.isRunningAllIntegrations = false
                            this.isRunningIndividualIntegration = true
                        }

                        clearTimeout(this.timeout)
                        this.timeout = setTimeout(() => {
                            this.getIntegrationStatus()
                        }, 5000)
                    } else {
                        this.runningIntegrations = []
                        this.isRunningAllIntegrations = false
                        this.isRunningIndividualIntegration = false
                        clearTimeout(this.timeout)
                    }
                }
            } catch (err) {
                this.runningIntegrations = []
                this.isRunningAllIntegrations = false
                this.isRunningIndividualIntegration = false
                clearTimeout(this.timeout)
                this.$fnError(err)
            }
        },

        handleRunIntegrations() {
            if (this.selectedIntegration) {
                this.runIntegration()
            } else {
                this.runIntegrations()
            }
        },

        async runIntegration() {
            this.dialogRunIntegrations = false

            let createPayload = {
                processing_month: parseInt(this.picker.slice(5, 7), 10),
                processing_year: parseInt(this.picker.slice(0, 4), 10),
                id_erp_integration: this.selectedIntegration.id_erp_integration,
                processing_status: 'NEW'
            }

            try {
                const createRes = await this.$http.post(`${this.$ipIntegration}erp-integration-run/create`, createPayload)
                if (createRes) {
                    await this.getIntegrationStatus()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async runIntegrations() {
            this.dialogRunIntegrations = false

            let createPayload = {
                items: this.apiResult.items.map(s => {
                    return {
                        processing_month: parseInt(this.picker.slice(5, 7), 10),
                        processing_year: parseInt(this.picker.slice(0, 4), 10),
                        id_erp_integration: s.id_erp_integration,
                        processing_status: 'NEW'
                    }
                })
            }

            try {
                const createRes = await this.$http.post(`${this.$ipIntegration}erp-integration-run/mass-create`, { ...createPayload })
                if (createRes) {
                    await this.getIntegrationStatus()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        selectIntegration(item) {
            this.selectedIntegration = item
            this.dialogRunIntegrations = true
        },

        isRunningThisIndividualIntegration(item) {
            let found = this.runningIntegrations.findIndex(i => i.id_erp_integration === item.id_erp_integration)
            return found > -1
        },

        enableIndividualIntegration(item) {
            return !!(item.id_erp_integration === 13 ||
                item.id_erp_integration === 14 ||
                item.id_erp_integration === 15 ||
                item.id_erp_integration === 17)
        },

        openDialogRunIntegrations() {
            this.selectedIntegration = null
            this.dialogRunIntegrations = true
        },

        closeDialogRunIntegrations() {
            this.dialogRunIntegrations = false
            this.selectedIntegration = null
        },

        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        onResize () {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },

        getItemKey() {
            return 'id_' + this.endpoint[1].replaceAll('-', '_')
        },

        refresh() {
            this.backupPage = this.pagination.page

            this.noData = true
            this.apiResult = {
                items: [],
                headers: [],
                columns: [],
            }

            this.load()
        },

        async load() {
            this.loading = true
            let payload = this.setPayload()

            try {
                const res = await this.$http.post(this.endpoint[0] + this.endpoint[1] + '/list', payload)

                this.apiResult = {
                    columns: res.data.columns,
                    headers: this.setHeaders(res.data.headers, res.data.columns),
                    items: this.setItems(res.data.rows, res.data.columns)
                }
                
                // Actions
                this.apiResult.headers.push({ text: '', value: 'actions', sortable: false })
                
                // Status
                this.apiResult.headers.push({ text: '', value: 'status', sortable: false })

                if (!this.apiResult.items.length) {
                    this.noDataMessage = 'Não há dados disponíveis'
                } else {
                    this.noData = false
                }

                this.pagination.page = this.backupPage
                this.loading = false

                return await this.getIntegrationStatus()
            } catch (err) {
                this.$fnError(err)
            }
        },

        setPayload() {
            return {
                filter: {
                    tableRows: this.tableRows,
                    conditions: [{
                        AndOr: "AND",
                        column: "from_erp_to_erp",
                        operator: "=",
                        value: "FROM_ERP"
                    }]
                }
            }
        },

        setItems(items, columns) {
            let dateColumns = columns.filter(c => c.columnType === 'DATE' || c.columnType === 'DATETIME')
            items.forEach(i => {
                dateColumns.forEach(c => {
                    if (i[c.columnAlias]) {
                        i[c.columnAlias] = moment.utc(i[c.columnAlias]).format("YYYY-MM-DD")
                    }
                })
            })
            
            return items
        },

        setHeaders(headers, columns) {
            let filteredHeaders = headers.filter(h => !h.hideColumn)

            return filteredHeaders.map((h, i) => {
                let width = ''
                let type = ''
                let rules = []
                let hide = false
                let key = ''
                let length = null
                let index = columns.map(column => column.columnAlias).indexOf(h.value)

                if (index === -1) {
                    index = i
                }

                if (h.width !== undefined ) {
                    width = h.width
                } else {
                    width = "1%"
                }

                if (h.type !== undefined) {
                    type = h.type
                } else {
                    if (h.file) {
                        type = 'IMG'
                    } else {
                        if (columns[index]) {
                            type = columns[index].columnType
                        }
                    }
                }

                if (h.rules !== undefined) {
                    rules.push(h.rules)
                } else {
                    let columnIdIndex = index

                    if (h.columnId) {
                        columnIdIndex = columns.map(column => column.columnAlias).indexOf(h.columnId)
                    }

                    if (columns[columnIdIndex]) {
                        if (columns[columnIdIndex].nullColumn === 'NO') {
                            rules.push('required')
                        }
                    }
                }

                // if h hide is true table column will show but will not appear at edit dialog
                if (h.hide !== undefined) {
                    hide = h.hide
                } else {
                    if (columns[index]) {
                        if (columns[index].key === 'MUL' || columns[index].key === 'PRI') {
                            hide = true
                            key = columns[index].key
                        }
                    }
                }
                
                if (h.length !== undefined) {
                    length = h.length
                } else {
                    if (columns[index]) {
                        length = columns[index].columnLength
                    }
                }
                
                return { ...h, width, type, rules, hide, key, length }
            })
        },

        updatePage (pagination) {
            if (pagination.page !== this.pagination.page) {
                pagination.page = this.pagination.page
            }
            this.pagination = pagination
        },

        closeTable() {
            if (!this.unsavedChanges) {
                this.$router.push('/')
            } else {
                this.dialogCloseTable = true
            }
        },
    },
}
</script>
